import React, { useState } from "react";
import Select from "react-select";
import { FaPills, FaClock, FaSave, FaCalendarAlt } from "react-icons/fa";
import { CardMonitoring } from "../component/Cards";
import { t } from "i18next";
import DosageInput from "./Quantity";

const frequencyOptions = [
  { value: "daily", label: "Tous les jours" },
  { value: "weekly", label: "Toutes les semaines" },
  { value: "custom-day", label: "Chaque n jours" },
  { value: "custom-week", label: "Chaque n semaines" },
  { value: "specific-days", label: "Jours spécifiques de la semaine" },
];

const daysOptions = [
  { value: "lundi", label: "Lundi" },
  { value: "mardi", label: "Mardi" },
  { value: "mercredi", label: "Mercredi" },
  { value: "jeudi", label: "Jeudi" },
  { value: "vendredi", label: "Vendredi" },
  { value: "samedi", label: "Samedi" },
  { value: "dimanche", label: "Dimanche" },
];

const Prescription: React.FC<{ onSave: (data: unknown) => void }> = ({
  onSave,
}) => {
  const [medication, setMedication] = useState("");
  const [dosage, setDosage] = useState("");
  const [frequency, setFrequency] = useState("");
  const [customFrequency, setCustomFrequency] = useState<number | undefined>(
    undefined
  );
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [startDate, setStartDate] = useState("");
  const [period, setPeriod] = useState("");
  const [showCard, setShowCard] = useState(false);

  const handleSave = () => {
    const prescriptionData = {
      medication,
      dosage,
      frequency:
        frequency === "custom-day" || frequency === "custom-week"
          ? `Chaque ${customFrequency} ${frequency === "custom-day" ? "jours" : "semaines"}`
          : frequency,
      selectedDays,
      startDate,
      endDate: new Date(
        new Date(startDate).getTime() + parseInt(period) * 24 * 60 * 60 * 1000
      ).toLocaleDateString(),
    };
    onSave(prescriptionData);
    setShowCard(true);
  };

  return (
    <div className="prescription-container p-6 bg-green-50 rounded-lg shadow-md mb-6 w-full">
      <h2 className="text-2xl font-semibold text-green-600 mb-6">
        {t("consultation.table_header.prescription")}
      </h2>

      <div className="mb-6">
        <label className="flex items-center font-semibold mb-2 text-green-600">
          <FaPills className="mr-2" />{" "}
          {t("consultation.table_header.Name_medication")}
        </label>
        <input
          type="text"
          value={medication}
          onChange={(e) => setMedication(e.target.value)}
          className="w-full p-3 border-2 border-green-300 rounded-lg"
          placeholder="Ex: Paracétamol"
        />
      </div>

      <DosageInput dosage={dosage} setDosage={setDosage} />

      <div className="mb-6">
        <label className="flex items-center font-semibold mb-2 text-green-600">
          <FaClock className="mr-2" />{" "}
          {t("consultation.table_header.frequency")}
        </label>
        <Select
          options={frequencyOptions}
          value={frequencyOptions.find((opt) => opt.value === frequency)}
          onChange={(opt) => {
            setFrequency(opt?.value || "");
            setCustomFrequency(undefined); // Reset custom frequency on change
            setSelectedDays([]); // Reset selected days on change
          }}
        />
      </div>

      {frequency === "custom-day" || frequency === "custom-week" ? (
        <div className="mb-6">
          <label className="flex items-center font-semibold mb-2 text-green-600">
            {frequency === "custom-day"
              ? "Nombre de jours"
              : "Nombre de semaines"}
          </label>
          <input
            type="number"
            value={customFrequency}
            onChange={(e) => setCustomFrequency(parseInt(e.target.value))}
            className="w-full p-3 border-2 border-green-300 rounded-lg"
            placeholder={`Ex: 3 ${frequency === "custom-day" ? "jours" : "semaines"}`}
          />
        </div>
      ) : null}

      {frequency === "specific-days" ? (
        <div className="mb-6">
          <label className="flex items-center font-semibold mb-2 text-green-600">
            Jours spécifiques
          </label>
          <Select
            options={daysOptions}
            isMulti
            value={daysOptions.filter((day) =>
              selectedDays.includes(day.value)
            )}
            onChange={(selected) =>
              setSelectedDays(selected ? selected.map((day) => day.value) : [])
            }
          />
        </div>
      ) : null}

      <div className="mb-6">
        <label className="flex items-center font-semibold mb-2 text-green-600">
          <FaCalendarAlt className="mr-2" />{" "}
          {t("consultation.table_header.start_date")}
        </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="w-full p-3 border-2 border-green-300 rounded-lg"
        />
      </div>

      <div className="mb-6">
        <label className="flex items-center font-semibold mb-2 text-green-600">
          <FaCalendarAlt className="mr-2" />{" "}
          {t("consultation.table_header.period")}
        </label>
        <input
          type="number"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          className="w-full p-3 border-2 border-green-300 rounded-lg"
        />
      </div>

      <button
        disabled
        onClick={handleSave}
        className="py-2 px-4 bg-green-600 text-white rounded-lg font-semibold shadow-md hover:bg-green-700 flex items-center mx-auto focus:outline-none focus:ring-2 focus:ring-green-300 mb-6"
      >
        {t("consultation.table_header.Save")} <FaSave className="ml-2" />
      </button>

      {showCard && (
        <CardMonitoring
          time={new Date().toLocaleDateString()}
          icone="prescription"
          title={medication}
          description={`${dosage} - ${frequency === "custom-day" || frequency === "custom-week" ? `Chaque ${customFrequency} ${frequency === "custom-day" ? "jours" : "semaines"}` : frequency}`}
          isWarning={false}
        />
      )}
    </div>
  );
};

export default Prescription;
