import React from "react";

interface ButtonIconProps {
  text: string;
  color: string;
  urlRedirection: string;
  icon: string;
  width: string;
  disabled: boolean;
  className?: string;
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  text,
  color,
  urlRedirection,
  icon,
  width,
  disabled,
  className = "",
}) => {
  return (
    <button
      className={`relative flex flex-col items-center justify-center p-4 lg;p-5 ${
        width ? `w-${width}` : "w-full"
      } border-2 border-blue-300 rounded-xl shadow-lg transition-transform duration-500 ease-out ${
        disabled
          ? "bg-gray-200 border-gray-300 cursor-not-allowed text-gray-500"
          : `bg-white text-${color} hover:shadow-xl hover:scale-105 transform hover:rotate-1 hover:bg-blue-50`
      } ${className}`}
      onClick={() => !disabled && (window.location.href = urlRedirection)}
      disabled={disabled}
    >
      <img
        src={icon}
        alt={`icon for ${text}`}
        className="w-14 h-14 md:w-16 md:h-16 transition-transform duration-300 hover:scale-110"
      />
      <span className="mt-2 md:text-lg lg:text-xl font-semibold text-center">
        {text}
      </span>
      {!disabled && (
        <div className="absolute inset-0 rounded-xl opacity-0 hover:opacity-20 bg-blue-100 transition-opacity duration-500" />
      )}
    </button>
  );
};

interface ButtonTextProps {
  text: string;
  color: string;
  onclick: () => void;
  className?: string;
}

export const ButtonText: React.FC<ButtonTextProps> = ({
  text,
  color,
  onclick,
  className = "",
}) => {
  return (
    <button
      className={`relative flex items-center justify-center py-4 px-6 w-48 h-12 bg-white rounded-lg border-2 border-blue-200 shadow-sm cursor-pointer transition-all duration-300 ease-out transform hover:scale-105 hover:bg-blue-50 active:bg-blue-100 active:scale-95 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:ring-opacity-50 text-${color} ${className}`}
      onClick={onclick}
    >
      {text}
      <div className="absolute inset-0 rounded-lg opacity-0 hover:opacity-10 bg-blue-300 transition-opacity duration-500" />
    </button>
  );
};
