import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const FindPharmacy = () => {
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  interface Pharmacy {
    display_name: string;
    address: {
      road: string;
      city: string;
    };
    distance: number;
  }

  const [pharmacy, setPharmacy] = useState<Pharmacy | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    try {
      // Étape 1 : Convertir l'adresse en coordonnées géographiques latitude et longitude (la seul facon que j'ai trouve)
      const geocodeResponse = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${address},+France&format=json&limit=1`
      );

      if (geocodeResponse.data.length === 0) {
        alert("Adresse introuvable. Veuillez réessayer.");
        setLoading(false);
        return;
      }

      const { lat, lon } = geocodeResponse.data[0];

      // Étape 2 : Rechercher les pharmacies proches en utilisant l'API OpenStreetMap mais je peux changer si elle est nul
      const pharmacyResponse = await axios.get(
        `https://api.openstreetmap.org/search?lat=${lat}&lon=${lon}&tag=pharmacy&radius=5000&format=json`
      );

      if (pharmacyResponse.data.length === 0) {
        alert("Aucune pharmacie trouvée à proximité.");
        setPharmacy(null);
      } else {
        setPharmacy(pharmacyResponse.data[0]); // On prend la première pharmacie trouvée
      }
    } catch (error) {
      console.error("Erreur lors de la recherche de pharmacie :", error);
      alert("Erreur lors de la recherche. Veuillez réessayer.");
    }
    setLoading(false);
  };

  return (
    <div className="mb-6">
      <label className="flex items-center font-semibold mb-2 text-green-600">
        <svg
          className="mr-2 w-6 h-6 text-green-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 10h.01M12 14h.01M16 18h.01M9 21h6a2 2 0 002-2v-5h-2.586a1 1 0 01-.707-.293l-3-3a1 1 0 00-1.414 0l-3 3A1 1 0 005 14V7a2 2 0 012-2h10a2 2 0 012 2v1M4 21h16"
          ></path>
        </svg>
        {t("consultation.find_pharmacy")}
      </label>
      <div className="flex items-center">
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="w-full p-3 border-2 border-green-300 rounded-l-lg"
          placeholder="Entrez votre adresse"
        />
        <button
          onClick={handleSearch}
          disabled
          className="px-6 py-3 bg-green-600 text-white font-semibold rounded-r-lg hover:bg-green-700 focus:outline-none"
        >
          {loading ? "Recherche..." : "Rechercher"}
        </button>
      </div>
      {pharmacy && (
        <div className="mt-4 p-4 border border-green-300 rounded-lg">
          <h3 className="font-semibold text-lg text-green-600">
            Pharmacie la plus proche
          </h3>
          <p>
            <strong>Nom:</strong> {pharmacy.display_name}
          </p>
          <p>
            <strong>Adresse:</strong> {pharmacy.address.road},{" "}
            {pharmacy.address.city}
          </p>
          <p>
            <strong>Distance:</strong> {Math.round(pharmacy.distance)} mètres
          </p>
        </div>
      )}
    </div>
  );
};

export default FindPharmacy;
