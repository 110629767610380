import React, { useEffect } from "react";
import { Register } from "./pages/Register";
import { Login } from "./pages/Login";
import { Children } from "./pages/Children";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { AboutChild } from "./pages/AboutChild";
import { Consultation } from "./pages/Consultation";
import { Administratif } from "./pages/Administratif";
import { CarePlan } from "./pages/CarePlan";
import { Monitoring } from "./pages/Monitoring/Monitoring";
import { Weight } from "./pages/Monitoring/Weight";
import { Poop } from "./pages/Monitoring/Stool";
import { Feeding } from "./pages/Monitoring/Feeding";
import { Treatment } from "./pages/Monitoring/Treatment";
import { Temperature } from "./pages/Monitoring/Temperature";
import { Vomiting } from "./pages/Monitoring/Vomiting";
import { ChildProfil } from "./pages/Profils/Child";
import { ParentProfil } from "./pages/Profils/Parent";
import { ProtectedLayout } from "./layouts/ProtectedLayout";
import { AuthProvider } from "./hooks/useAuth";
import PharmacyBox from "./pages/PharmacyBox/PharmacyBox";
import { NavBar } from "./component/NavBar";
import NewChild from "./pages/NewChild/NewChild";

function App() {
  useEffect(() => {
    document.title = "BA'SSIST - Votre outil de suivi pédiatrique";
  });

  return (
    <SnackbarProvider>
      <AuthProvider>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route element={<ProtectedLayout />}>
              <Route
                path="/"
                element={<Navigate to="/login" replace={false} />}
              />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/children" element={<Children />} />
              <Route path="/new-child" element={<NewChild />} />
              <Route path="/about-child/:id" element={<AboutChild />} />
              <Route path="/consultation/:id" element={<Consultation />} />
              <Route path="/administration" element={<Administratif />} />
              <Route path="/monitoring/:id" element={<Monitoring />} />
              <Route path="/monitoring/weight/:id" element={<Weight />} />
              <Route path="/monitoring/poop/:id" element={<Poop />} />
              <Route path="/monitoring/feeding/:id" element={<Feeding />} />
              <Route path="/monitoring/vomiting/:id" element={<Vomiting />} />
              <Route path="/monitoring/treatment/:id" element={<Treatment />} />
              <Route
                path="/monitoring/temperature/:id"
                element={<Temperature />}
              />
              <Route path="/child-profile/:id" element={<ChildProfil />} />
              <Route path="/parent-profil" element={<ParentProfil />} />
              <Route path="/careplan/:id" element={<CarePlan />} />
              <Route path="/pharmacy_box" element={<PharmacyBox />} />
            </Route>
            {/* 404 : not found */}
            <Route path="*" element={<Navigate to="/home" replace={true} />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default App;
