import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface BackToMenuButtonProps {
  label?: string;
  to?: string;
  className?: string;
}

export const BackToMenuButton: React.FC<BackToMenuButtonProps> = ({
  label,
}) => {
  const { t } = useTranslation();
  label = label || t("buttons.Return");
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <button
      onClick={handleClick}
      className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
    >
      <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
        <FontAwesomeIcon icon={faArrowLeft} className="text-white" />
      </span>
      <span>{label}</span>
    </button>
  );
};
