/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import Check from "../../assets/icons/check-black.png";
import Question from "../../assets/icons/question-black.png";
import { useTranslation } from "react-i18next";
import { Table } from "../../component/Tables";
import { useParams } from "react-router-dom";
import axios from "../../lib/config/axios";
import { useAuth } from "../../hooks/useAuth";
import Tabs from "../../component/Tabs/Tabs";
import PendingSVG from "../../assets/icons/Pending";
import { enqueueSnackbar } from "notistack";
import TableCheck from "../../component/TableCheck/TableCheck";
// TODO : traduire les manifestations des allergies en francais

// Exemple de typage explicite
type VaccineStatus = "" | "check" | "question"; // Statuts possibles pour une cellule
type VaccineRow = VaccineStatus[]; // Une ligne de vaccins
type VaccineTable = VaccineRow[]; // Le tableau complet

export const ChildProfil = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAuth();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const [activeTab, setActiveTab] = useState<string>("step1");
  interface MedicalBackground {
    name: string;
    date: string;
  }

  interface Allergy {
    id: number;
    manifestation: string;
    date: string;
  }

  const [background, setBackground] = useState<MedicalBackground[]>([]);
  const [allergies, setAllergies] = useState<Allergy[]>([]);
  const [setMedications] = useState([]);
  const [vaccinationsList, setVaccinationsList] = useState<any[]>([]);
  const [vacinationData, setVacinationData] = useState<any[]>([]);
  const [size, setSize] = useState([]);
  const [weight, setWeight] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [formValues, setFormValues] = useState({
    firstname: child.firstname || "",
    gender: child.gender || "",
    gestationalAgeAtBirth: child.gestationalAgeAtBirth || "",
    birthdate: child.birthdate || "",
    size: child.size || "",
    weight: child.weight || "",
  });

  useEffect(() => {
    const fetchBackground = async () => {
      try {
        const response = await axios.get(`/children/${id}/all-data`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        setBackground(response.data.medicalBackgrounds);
        setAllergies(response.data.allergies);
        // setMedications(response.data.medications);
        setVacinationData(response.data.vaccinations);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchSizeAndWeight = async () => {
      try {
        const response = await axios.get(`/children/${id}`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        setSize(response.data.size.value);
        setWeight(response.data.weight.value);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBackground();
    fetchSizeAndWeight();
  }, []);

  const handleSaveChanges = async () => {
    if (!formValues.firstname || !formValues.gender || !formValues.birthdate) {
      enqueueSnackbar("Certains champs obligatoires sont manquants.", {
        variant: "error",
      });
      return;
    }
    try {
      const payload = {
        ...formValues,
        size: formValues.size ? Number(formValues.size) : undefined,
        weight: formValues.weight ? Number(formValues.weight) : undefined,
        medicalBackgrounds: background.map((item) => ({
          name: item.name,
          date: item.date,
        })),
        allergies: allergies.map((allergy) => ({
          allergyId: allergy.id,
          manifestation: allergy.manifestation,
          date: allergy.date,
        })),
      };
      console.log("Payload final :", payload);

      const response = await axios.put(`/children/${id}`, payload, {
        headers: { Authorization: `Bearer ${user}` },
      });
      console.log("Réponse du serveur :", response.data);

      enqueueSnackbar("Les modifications ont été sauvegardées.", {
        variant: "success",
      });
      setIsEditing(false);
    } catch (error) {
      enqueueSnackbar("Les modifications ne sont pas sauvegardées.", {
        variant: "error",
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleTabClick = (label: string) => {
    setActiveTab(label);
    const element = document.getElementById(label);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  interface AllergiesItem {
    id: number;
    createdAt: string;
    name: string;
    allergy: { name: string };
    manifestation: string;
    date: string;
  }

  const headersAnte = [t("mesure.date"), t("new_child.stepper.step2"), ""];

  const headersAllergies = [
    t("mesure.date"),
    t("new_child.stepper.step3"),
    t("new_child.form_label.manifestation"),
    "",
  ];

  const headersMed = [
    t("mesure.date"),
    t("new_child.stepper.step5"),
    t("new_child.form_label.frequency"),
    "",
  ];

  const rowsAllergies = allergies.map((item: Allergy) => {
    return [
      new Date(item.date).toLocaleDateString(),
      item.manifestation,
      item.date,
      "",
    ];
  });

  const vacinationColumns = [
    { key: "col1", value: t("new_child.form-data.vacinationColumns.col1") },
    { key: "col2", value: t("new_child.form-data.vacinationColumns.col2") },
    { key: "col3", value: t("new_child.form-data.vacinationColumns.col3") },
    { key: "col4", value: t("new_child.form-data.vacinationColumns.col4") },
    { key: "col5", value: t("new_child.form-data.vacinationColumns.col5") },
    { key: "col6", value: t("new_child.form-data.vacinationColumns.col6") },
    { key: "col7", value: t("new_child.form-data.vacinationColumns.col7") },
    { key: "col8", value: t("new_child.form-data.vacinationColumns.col8") },
    { key: "col9", value: t("new_child.form-data.vacinationColumns.col9") },
    { key: "col10", value: t("new_child.form-data.vacinationColumns.col10") },
  ];

  const vacinationLines = [
    { key: "line1", value: t("new_child.form-data.vacinationLines.line1") },
    { key: "line2", value: t("new_child.form-data.vacinationLines.line2") },
    { key: "line3", value: t("new_child.form-data.vacinationLines.line3") },
    { key: "line4", value: t("new_child.form-data.vacinationLines.line4") },
    { key: "line5", value: t("new_child.form-data.vacinationLines.line5") },
    { key: "line6", value: t("new_child.form-data.vacinationLines.line6") },
    { key: "line7", value: t("new_child.form-data.vacinationLines.line7") },
    { key: "line8", value: t("new_child.form-data.vacinationLines.line8") },
    // Uncomment these if needed
    // { key: "line9", value: t("new_child.form-data.vacinationLines.line9") },
    // { key: "line10", value: t("new_child.form-data.vacinationLines.line10") },
    // { key: "line11", value: t("new_child.form-data.vacinationLines.line11") },
    { key: "line12", value: t("new_child.form-data.vacinationLines.line12") },
  ];

  const rowsMed = [[]];

  return (
    <div className="w-screen">
      <section className="flex flex-col mx-24">
        <Tabs
          tabs={[{ label: t("new_child.stepper.step1"), value: "step1" }]}
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />
        <div id="step1" className="flex flex-col mt-8">
          <h2 className="text-2xl font-semibold mb-4">
            {t("new_child.stepper.step1")}
          </h2>
          <div className="grid gap-6 mb-6 md:grid-cols-3">
            <div>
              <label className="block mb-2 text-md font-semibold text-darkgrey">
                {t("new_child.form_label.firstname")}
              </label>
              {isEditing ? (
                <input
                  type="text"
                  name="firstname"
                  value={formValues.firstname}
                  onChange={handleInputChange}
                  className="bg-white border-2 border-blue-500 text-gray-900 text-sm rounded-full block w-full p-2.5"
                />
              ) : (
                <p className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full block w-full p-2.5">
                  {formValues.firstname}
                </p>
              )}
            </div>
            <div>
              <label className="block mb-2 text-md font-semibold text-darkgrey">
                {t("new_child.form_label.genre")}
              </label>
              {isEditing ? (
                <input
                  type="text"
                  name="gender"
                  value={formValues.gender}
                  onChange={handleInputChange}
                  className="bg-white border-2 border-blue-500 text-gray-900 text-sm rounded-full block w-full p-2.5"
                />
              ) : (
                <p className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full block w-full p-2.5">
                  {formValues.gender === "MALE"
                    ? t("new_child.form_label.genres.male")
                    : t("new_child.form_label.genres.female")}
                </p>
              )}
            </div>
            <div>
              <label className="block mb-2 text-md font-semibold text-darkgrey">
                {t("new_child.form_label.gestation_time")}
              </label>
              {isEditing ? (
                <input
                  type="text"
                  name="gestationalAgeAtBirth"
                  value={formValues.gestationalAgeAtBirth}
                  onChange={handleInputChange}
                  className="bg-white border-2 border-blue-500 text-gray-900 text-sm rounded-full block w-full p-2.5"
                />
              ) : (
                <p className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full block w-full p-2.5">
                  {formValues.gestationalAgeAtBirth}
                </p>
              )}
            </div>
            <div>
              <label className="block mb-2 text-md font-semibold text-darkgrey">
                {t("new_child.form_label.birthdate")}
              </label>
              {isEditing ? (
                <input
                  type="date"
                  name="birthdate"
                  value={formValues.birthdate.split("T")[0]}
                  onChange={handleInputChange}
                  className="bg-white border-2 border-blue-500 text-gray-900 text-sm rounded-full block w-full p-2.5"
                />
              ) : (
                <p className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full block w-full p-2.5">
                  {formValues.birthdate
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join(" / ")}
                </p>
              )}
            </div>
            <div>
              <label className="block mb-2 text-md font-semibold text-darkgrey">
                {t("mesure.height")}
              </label>
              {isEditing ? (
                <input
                  type="number"
                  name="size"
                  value={formValues.size}
                  onChange={handleInputChange}
                  className="bg-white border-2 border-blue-500 text-gray-900 text-sm rounded-full block w-full p-2.5"
                />
              ) : (
                <p className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full block w-full p-2.5">
                  {formValues.size} {t("new_child.form_label.size")}
                </p>
              )}
            </div>
            <div>
              <label className="block mb-2 text-md font-semibold text-darkgrey">
                {t("mesure.weight")}
              </label>
              {isEditing ? (
                <input
                  type="number"
                  name="weight"
                  value={formValues.weight}
                  onChange={handleInputChange}
                  className="bg-white border-2 border-blue-500 text-gray-900 text-sm rounded-full block w-full p-2.5"
                />
              ) : (
                <p className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full block w-full p-2.5">
                  {formValues.weight} {t("new_child.form_label.weight")}
                </p>
              )}
            </div>
          </div>
          <div className="flex gap-4">
            {isEditing ? (
              <button
                type="button"
                onClick={handleSaveChanges}
                className="px-6 py-3 bg-blue-600 text-white rounded-full shadow-md hover:bg-blue-700 transition duration-300 ease-in-out"
              >
                {t("buttons.save")}
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setIsEditing(true)}
                className="px-6 py-3 bg-green-600 text-white rounded-full shadow-md hover:bg-green-700 transition duration-300 ease-in-out"
              >
                {t("buttons.edit")}
              </button>
            )}
          </div>
          <div id="step3" className="flex flex-col mt-8">
            <h2 className="text-2xl font-semibold mb-4">
              {t("new_child.stepper.step3")}
            </h2>
            {!rowsAllergies.length ? (
              <p>{t("new_child.form_label.no_allergies")}</p>
            ) : (
              <Table
                headers={headersAllergies}
                rows={rowsAllergies.map((row) =>
                  row.map((item) => String(item))
                )}
              />
            )}
          </div>
          <div id="step4">
            <h2>{t("new_child.stepper.step4")}</h2>
            <TableCheck
              tableList={vaccinationsList}
              data={vacinationData}
              setData={setVacinationData}
              columns={vacinationColumns}
              lines={vacinationLines}
              columnsName={{
                key: "age",
                title: t("new_child.form_label.age"),
              }}
            />
          </div>
          <div id="step5" className="flex flex-col my-8">
            <h2 className="text-2xl font-semibold mb-4">
              {t("new_child.stepper.step5")}
            </h2>
            {!rowsMed.length ? (
              <p>{t("new_child.form_label.no_treatment")}</p>
            ) : (
              <Table
                headers={headersMed}
                rows={rowsMed.map((row) => row.map((item) => String(item)))}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
