import React from "react";
import { ButtonIcon } from "../../component/Button";
import Temperature from "../../assets/icons/46-removebg-preview.png";
import ToiletPaper from "../../assets/icons/47-removebg-preview.png";
import Vomit from "../../assets/icons/48-removebg-preview.png";
import Bottle from "../../assets/icons/51-removebg-preview.png";
import Camera from "../../assets/icons/73-removebg-preview.png";
import Pills from "../../assets/icons/56-removebg-preview.png";
import Balance from "../../assets/icons/58-removebg-preview.png";
import Add from "../../assets/icons/72-removebg-preview.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../../component/Header";

export const Monitoring = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");

  return (
    <div className="w-screen min-h-screen flex flex-col items-center bg-gradient-to-b from-blue-50 to-blue-100">
      <Header
        title={t("titles.monitoring")}
        subtitle={t("monitoring.main_title")}
        titleColor="pink"
        childInfo={{
          picture: child.image_id,
          name: child.firstname,
          urlRedirection: `/child-profile/${child.id}`,
        }}
        showBackToMenu={true}
      />
      <section className="flex flex-col justify-center">
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-6 max-w-6xl mt-12">
          {[
            {
              text: t("monitoring.button_text.temperature"),
              icon: Temperature,
              url: `/monitoring/temperature/${child.id}`,
            },
            {
              text: t("monitoring.button_text.treatment"),
              icon: Pills,
              url: `/monitoring/treatment/${child.id}`,
            },
            {
              text: t("monitoring.button_text.vomiting"),
              icon: Vomit,
              url: `/monitoring/vomiting/${child.id}`,
            },
            {
              text: t("monitoring.button_text.feeding"),
              icon: Bottle,
              url: `/monitoring/feeding/${child.id}`,
            },
            {
              text: t("monitoring.button_text.poop"),
              icon: ToiletPaper,
              url: `/monitoring/poop/${child.id}`,
            },
            {
              text: t("monitoring.button_text.weight"),
              icon: Balance,
              url: `/monitoring/weight/${child.id}`,
            },
            {
              text: t("monitoring.button_text.images"),
              icon: Camera,
              url: "/consultation",
              disabled: true,
            },
            {
              text: t("monitoring.button_text.new_monitoring"),
              icon: Add,
              url: "/monitoring",
              disabled: true,
            },
          ].map(({ text, icon, url, disabled = false }, index) => (
            <ButtonIcon
              key={index}
              text={text}
              color={disabled ? "gray-500" : "blue-900"}
              urlRedirection={url}
              icon={icon}
              width="full"
              disabled={disabled}
              className={`${
                disabled
                  ? "bg-gray-100 border-gray-300 cursor-not-allowed text-gray-500"
                  : "bg-white shadow-lg hover:shadow-2xl transform hover:scale-105 hover:rotate-1 transition-transform duration-500 ease-out"
              } p-8 text-lg flex flex-col items-center justify-center rounded-lg`}
            />
          ))}
        </div>
      </section>
    </div>
  );
};
