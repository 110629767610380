/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from "react";
import "./Table.scss";
import AddSVG from "../../assets/icons/Add";
import { t } from "i18next";
import { FaEdit, FaTimes } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const TableRow = ({
  columns,
  row,
  onSelect,
  onDelete,
}: {
  columns: any[];
  row: any;
  onSelect: () => void;
  onDelete: () => void;
}) => (
  <tr>
    {columns.map((col, index) => (
      <td key={index}>{row[col.accessor]}</td>
    ))}
    <td className="action-buttons">
      {/* Bouton de modification */}
      <button onClick={onSelect} className="button-edit">
        <FaEdit size={18} />
        <span>Modifier</span>
      </button>
      {/* Bouton de suppression */}
      <button onClick={onDelete} className="button-delete">
        <FaTimes size={18} />
        <span>Supprimer</span>
      </button>
    </td>
  </tr>
);

const Table = ({
  title,
  data,
  columns,
  allergyList,
  manifestationList,
  medicineList,
  add,
  remove,
  onSelected,
}: {
  title: string;
  data: any[];
  columns: any[];
  allergyList?: any[];
  manifestationList?: { id: string; name: string }[];
  medicineList?: any[];
  add: (item: never) => void;
  remove: (data: any[]) => void;
  onSelected?: (item: any) => void;
}) => {
  const [viewNewItem, setViewNewItem] = useState(false);
  const [newItem, setNewItem] = useState({});

  const canAddItem = useMemo(
    () =>
      Object.keys(newItem).length == columns.length &&
      Object.values(newItem).filter((n) => n != "").length ==
        Object.keys(newItem).length,
    [newItem, columns.length]
  );

  const onConfirm = () => {
    add(newItem as never);
    setNewItem({});
    setViewNewItem(false);
  };

  const onChangeData = (type: string, value: string) => {
    setNewItem({ ...newItem, [type]: value });
  };

  return (
    <div className="table-container">
      <h2>{title}</h2>
      <div className="styled-background-table">
        <table className="styled-table">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index}>{col.header.toUpperCase()}</th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                columns={columns}
                row={row}
                onDelete={() => remove(data.filter((_, i) => i !== rowIndex))}
                onSelect={() => onSelected && onSelected(row)}
              />
            ))}
          </tbody>
        </table>
      </div>
      {viewNewItem ? (
        <div className="add-container">
          {columns.map((col, idx) => (
            <div className="add-item" key={idx}>
              <p>{col.header}</p>
              {col.component == "input" ? (
                <input
                  type={col.type}
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                  placeholder={col.placeholder}
                />
              ) : col.type === "allergyList" ? (
                <Autocomplete
                  onChange={(e, newValue) => {
                    onChangeData(col.accessor, newValue);
                  }}
                  disablePortal
                  // @ts-expect-error error
                  options={allergyList}
                  getOptionLabel={(option) => option.name}
                  groupBy={(option) => option.type}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="" />}
                />
              ) : col.type === "manifestationList" ? (
                <select
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                >
                  <option value={"chooseOption"} disabled selected>
                    {t("new_child.form-data.placeholder.chooseOption")}
                  </option>
                  {manifestationList?.map((a) => (
                    <option key={a.id} value={a.name}>
                      {a.name}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                >
                  <option value={"chooseOption"} disabled selected>
                    {t("new_child.form-data.placeholder.chooseOption")}
                  </option>
                  {medicineList?.map((a) => (
                    <option key={a} value={a}>
                      {a}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
          <button
            className="add-button"
            type="button"
            onClick={onConfirm}
            disabled={!canAddItem}
          >
            <span>AJOUTER</span>
          </button>
        </div>
      ) : (
        <button
          className="add-button"
          type="button"
          onClick={() => setViewNewItem(true)}
        >
          <AddSVG />
          <span>AJOUTER</span>
        </button>
      )}
    </div>
  );
};

export default Table;
