import React, { useEffect, useState } from "react";
import { ButtonIcon } from "../component/Button";
import Checklist from "../assets/icons/67-removebg-preview.png";
import MagnifyingGlass from "../assets/icons/66-removebg-preview.png";
import Stethoscope from "../assets/icons/65-removebg-preview.png";
import Admin from "../assets/icons/68-removebg-preview.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "../lib/config/axios";
import { useAuth } from "../hooks/useAuth";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { AboutChildPdf } from "./AboutChildPdf";
import Header from "../component/Header";
import { ChildCard } from "../component/ChildCard";

interface ChildInfos {
  child: {
    id: number;
    firstname: string;
    birthdate: string;
    gender: string;
    createdAt: string;
    firstParentId: number;
    secondParentId: number;
    gestionalAgeAtBirth: number;
    image_id: string;
    updatedAt: string;
  };
  size: {
    id: number;
    childId: number;
    value: number;
    dateTime: string;
  };
  weight: {
    id: number;
    childId: number;
    value: number;
    dateTime: string;
  };
}

const monthDiff = (startDate: Date, endDate: Date) =>
  Math.max(
    0,
    (endDate.getFullYear() - startDate.getFullYear()) * 12 -
      startDate.getMonth() +
      endDate.getMonth()
  );

export const AboutChild = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const [childInfos, setChildInfos] = useState<ChildInfos | null>(null);
  const [childBackgrounds, setChildBackgrounds] = useState<unknown | null>(
    null
  );

  const fetchChildInfos = async () => {
    const fetch = await axios.get(`/children/${id}`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });

    if (fetch.status === 200 && fetch.data) {
      setChildInfos(fetch.data);
    }
  };

  const fetchChildBackgrounds = async () => {
    const fetch = await axios.get(`/children/${id}/all-data`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });

    if (fetch.status === 200 && fetch.data) {
      setChildBackgrounds(fetch.data);
    }
  };

  useEffect(() => {
    fetchChildInfos();
    fetchChildBackgrounds();
  }, []);

  return (
    <div className="w-screen min-h-screen flex flex-col items-center bg-gradient-to-b from-blue-50 to-blue-100">
      <Header
        title={t("titles.about_child")}
        titleColor="blue"
        showBackToMenu={true}
      />
      <section className="flex flex-col lg:flex-row lg:items-center justify-between items-start w-full px-4 md:px-10 py-6">
        {/* Partie gauche */}
        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-center space-y-8">
          <ChildCard
            picture={child.image_id}
            name={child.firstname}
            urlRedirection={`/child-profile/${child.id}`}
            hoverColor="blue-600"
            height="w-60 h-60 md:w-64 md:h-64"
            fontSize="3xl"
          />
          <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
            <div className="flex flex-wrap justify-center lg:justify-start space-x-6">
              {[
                {
                  label: t("mesure.age"),
                  value: `${monthDiff(new Date(childInfos?.child.birthdate || new Date()), new Date())} mois`,
                },
                {
                  label: t("mesure.weight"),
                  value: `${childInfos?.weight.value} kg`,
                },
                {
                  label: t("mesure.height"),
                  value: `${childInfos?.size.value} cm`,
                },
              ].map((info, index) => (
                <div
                  key={index}
                  className="mb-2 flex flex-col items-center lg:items-start"
                >
                  <p className="text-primary font-bold text-xl md:text-2xl">
                    {info.label}
                  </p>
                  <span className="text-gray-900 text-lg md:text-xl">
                    {info.value}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div>
            <PDFDownloadLink
              document={
                <AboutChildPdf
                  childInfos={childInfos}
                  childBackgrounds={childBackgrounds}
                />
              }
              fileName={`je-dis-quoi-au-docteur-${childInfos?.child.firstname}-${new Date().toLocaleDateString()}.pdf`}
            >
              <button className="mt-4 bg-gradient-to-r from-blue-600 to-blue-400 text-white font-semibold text-xl py-4 px-12 rounded-full shadow-lg hover:scale-105 transition-transform">
                {t("titles.Say_doctor")}
              </button>
            </PDFDownloadLink>
          </div>
        </div>

        {/* Partie droite */}
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center lg:items-start">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
            <ButtonIcon
              text={t("titles.consultation")}
              color="black"
              urlRedirection={`/consultation/${id}`}
              icon={Stethoscope}
              width={""}
              disabled={false}
            />
            <ButtonIcon
              text={t("titles.monitoring")}
              color="black"
              urlRedirection={`/monitoring/${id}`}
              icon={MagnifyingGlass}
              width={""}
              disabled={false}
            />
            <ButtonIcon
              text={t("titles.care_plan")}
              color="black"
              urlRedirection={`/careplan/${id}`}
              icon={Checklist}
              width={""}
              disabled={false}
            />
            <ButtonIcon
              text={t("titles.administrative")}
              color="black"
              urlRedirection="/administration"
              icon={Admin}
              width={""}
              disabled={false}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
