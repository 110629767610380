const AddSVG = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 24 24" {...props}>
      <g>
        <line
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
          x1="12"
          x2="12"
          y1="19"
          y2="5"
        />
        <line
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
          x1="5"
          x2="19"
          y1="12"
          y2="12"
        />
      </g>
    </svg>
  );
};

export default AddSVG;
