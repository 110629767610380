import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "notistack";
import axios from "../../lib/config/axios";
import Header from "../../component/Header";
interface Parent {
  pseudo?: string;
  email?: string;
}

export const ParentProfil = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [parent, setParent] = useState<Parent>({});
  const [isEditable, setIsEditable] = useState(false);

  // First letter of username for avatar display
  const firstLetter = parent.pseudo?.charAt(0).toUpperCase() || "?";

  useEffect(() => {
    const fetchParentProfile = async () => {
      try {
        const response = await axios.get("/users/profile", {
          headers: { Authorization: `Bearer ${user}` },
        });
        setParent(response.data);
      } catch (err) {
        console.error("Failed to fetch profile:", err);
        enqueueSnackbar(t("errors.profile_load_failed"), {
          variant: "error",
        });
      }
    };
    fetchParentProfile();
  }, [user, t, enqueueSnackbar]);

  const handleLogout = async () => {
    try {
      await axios.post(
        "/users/logout",
        {},
        { headers: { Authorization: `Bearer ${user}` } }
      );
      logout();
      enqueueSnackbar(t("messages.logout_success"), { variant: "success" });
      window.location.href = "/login";
    } catch {
      enqueueSnackbar(t("errors.logout_failed"), { variant: "error" });
    }
  };

  const toggleEditMode = () => {
    setIsEditable(!isEditable);
  };

  const handleSave = () => {
    // Placeholder for save functionality
    enqueueSnackbar(t("messages.profile_updated"), { variant: "success" });
    setIsEditable(false);
  };

  return (
    <div className="max-h-screen">
      <Header
        title={t("titles.about_child")}
        titleColor="blue"
        showBackToMenu={true}
      />
      <section className="container mx-auto flex flex-col items-center">
        <div className="flex flex-col items-center mt-8 gap-8">
          <div className="bg-pink-500 w-32 h-32 md:w-40 md:h-40 rounded-full flex items-center justify-center text-white font-semibold text-6xl shadow-lg">
            {firstLetter}
          </div>
          <button
            onClick={handleLogout}
            className="bg-blue-300 hover:bg-blue-400 text-white font-semibold py-2 px-8 rounded-full shadow-lg transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-blue-200 flex items-center"
          >
            {t("buttons.logout")}
          </button>
        </div>

        {/* Editable Profile Form */}
        <div className="mt-12 w-full md:w-2/3 lg:w-1/2">
          <form className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg space-y-6">
            <div className="flex items-center w-full gap-4">
              <label className="text-gray-600 font-semibold">
                {t("infos.pseudo")}
              </label>
              <input
                type="text"
                value={parent.pseudo}
                onChange={(e) =>
                  setParent({ ...parent, pseudo: e.target.value })
                }
                disabled={!isEditable}
                placeholder={parent.pseudo || t("placeholders.username")}
                className={`flex-grow py-2 px-4 rounded-full border ${
                  isEditable
                    ? "border-blue- 00 bg-white"
                    : "border-gray-200 bg-gray-100"
                } text-gray-700 transition duration-200 focus:outline-none focus:border-blue-400`}
              />
            </div>

            <div className="flex items-center w-full gap-4">
              <label className="text-gray-600 font-semibold">
                {t("infos.email")}
              </label>
              <input
                type="email"
                value={parent.email}
                onChange={(e) =>
                  setParent({ ...parent, email: e.target.value })
                }
                disabled={!isEditable}
                placeholder={parent.email || t("placeholders.email")}
                className={`flex-grow py-2 px-4 rounded-full border ${
                  isEditable
                    ? "border-blue-300 bg-white"
                    : "border-gray-200 bg-gray-100"
                } text-gray-700 transition duration-200 focus:outline-none focus:border-blue-400`}
              />
            </div>

            {isEditable && (
              <>
                <div className="flex items-center w-full gap-4">
                  <label className="text-gray-600 font-semibold">
                    {t("register.password")}
                  </label>
                  <input
                    type="password"
                    placeholder={t("infos.enter_new_password")}
                    className="flex-grow py-2 px-4 rounded-full border border-blue-300 bg-white text-gray-700 focus:outline-none focus:border-blue-400"
                  />
                </div>
                <div className="flex items-center w-full gap-4">
                  <label className="text-gray-600 font-semibold">
                    {t("register.password_confirmation")}
                  </label>
                  <input
                    type="password"
                    placeholder={t("infos.new_password_confirmation")}
                    className="flex-grow py-2 px-4 rounded-full border border-blue-300 bg-white text-gray-700 focus:outline-none focus:border-blue-400"
                  />
                </div>
              </>
            )}

            {/* Toggle and Save Button */}
            <div className="flex items-center justify-center w-full space-x-6">
              <label className="flex items-center cursor-pointer">
                <span className="text-gray-600 font-semibold mr-3">
                  {t("infos.edit_password")}
                </span>
                <input
                  type="checkbox"
                  checked={isEditable}
                  onChange={toggleEditMode}
                  className="sr-only"
                />
                <div
                  className={`relative w-10 h-5 rounded-full transition-colors duration-300 ${
                    isEditable ? "bg-blue-300" : "bg-gray-300"
                  }`}
                >
                  <span
                    className={`absolute top-0.5 left-0.5 w-4 h-4 bg-white rounded-full transform transition-transform ${
                      isEditable ? "translate-x-5" : ""
                    }`}
                  ></span>
                </div>
              </label>

              {isEditable && (
                <button
                  onClick={handleSave}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-full shadow-lg transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-blue-200"
                >
                  {t("buttons.save")}
                </button>
              )}
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};
