import "./MedicationCard.scss";
import MedicationImg from "../../../../assets/Medications/medicationsImg.jpg";
import moment from "moment";
import ArrowRight from "../../../../assets/icons/Arrow/ArrowRight";
import { MedicationsType } from "../../PharmacyBox.d";
import { t } from "i18next";
import CrossSVG from "../../../../assets/icons/Cross";

const MedicationCard = ({
  expiryDate,
  quantity,
  id,
  medicineId,
  pharmarcyBoxId,
  medicine,
  onClick,
  onDelete,
}: MedicationsType & { onClick: () => void; onDelete: () => void }) => {
  return (
    <div className="medication-card" onClick={onClick}>
      <div className="medication-img">
        <img src={MedicationImg} />
      </div>
      <div className="medication-information">
        <div>
          {t("pharmacy_box.name")} : {medicine.name}
        </div>
        <div>
          {t("pharmacy_box.code")} : {medicine.cipCode}
        </div>
        <div>
          {t("pharmacy_box.expery_date")} : 
          {expiryDate ? moment(expiryDate).format("MM/YYYY") : "--"}
        </div>
        <div>
          {t(`pharmacy_box.box_number${quantity > 1 ? "_plural" : ""}`, {
            quantity,
          })}
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <CrossSVG />
      </div>
    </div>
  );
};

export default MedicationCard;
