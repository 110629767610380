/* eslint-disable react/react-in-jsx-scope */
const PendingSVG = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 1024 1024"
      version="1.1"
      {...props}
    >
      <path
        d="M511.9 183c-181.8 0-329.1 147.4-329.1 329.1s147.4 329.1 329.1 329.1c181.8 0 329.1-147.4 329.1-329.1S693.6 183 511.9 183z m0 585.2c-141.2 0-256-114.8-256-256s114.8-256 256-256 256 114.8 256 256-114.9 256-256 256z"
        fill="#0F1F3C"
      />
      <path
        d="M548.6 365.7h-73.2v161.4l120.5 120.5 51.7-51.7-99-99z"
        fill="#0F1F3C"
      />
    </svg>
  );
};

export default PendingSVG;
