import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CardMonitoring } from "../component/Cards";
import axios from "../lib/config/axios";
import { useAuth } from "../hooks/useAuth";
import Header from "../component/Header";
import Prescription from "../component/Prescription";
import InfoAlert from "../component/InfoAlert";
import FindPharmacy from "../component/FindPharmacy";

interface Pathology {
  id: number;
  name: string;
  description?: string;
}

interface ChildPathologies {
  id: number;
  pathologyId: number;
  date: string;
  notes: string;
  startDate: string;
  endDate: string;
}

export const Consultation = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");

  const [form, setForm] = useState({
    date: "",
    pathology: "",
    comment: "",
    treatment_start_date: "",
    treatment_end_date: "",
  });
  const [pathologiesList, setPathologiesList] = useState<Pathology[]>([]);
  const [childPathologiesList, setChildPathologies] = useState<
    ChildPathologies[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      const pathologiesResponse = await axios.get("/pathologies", {
        headers: { Authorization: `Bearer ${user}` },
      });
      setPathologiesList(
        // @ts-expect-error - Sort by id
        pathologiesResponse.data.sort((a, b) => a.name.localeCompare(b.name))
      );

      const consultationsResponse = await axios.get(
        `/children/${child.id}/consultations`,
        {
          headers: { Authorization: `Bearer ${user}` },
        }
      );
      setChildPathologies(consultationsResponse.data);
    };

    fetchData();
  }, [child.id, user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const selectedPathology = pathologiesList.find(
      (p) => p.name === form.pathology
    );

    if (!selectedPathology) {
      console.error("Pathology not selected properly.");
      return;
    }

    try {
      await axios.post(
        `/children/${child.id}/consultations`,
        {
          consultations: [
            {
              pathologyId: selectedPathology.id,
              date: form.date,
              notes: form.comment,
              startDate: form.treatment_start_date,
              endDate: form.treatment_end_date,
            },
          ],
        },
        {
          headers: { Authorization: `Bearer ${user}` },
        }
      );
    } catch (error) {
      console.error("Error adding consultation:", error);
    }
  };

  return (
    <div className="w-full">
      <Header
        title={t("titles.consultation")}
        titleColor="green"
        childInfo={{
          picture: child.image_id,
          name: child.firstname,
          urlRedirection: `/child-profile/${child.id}`,
        }}
        showBackToMenu
      />

      <section className="flex flex-col px-6 lg:px-12 w-full">
        <form
          className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8 mb-12"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col mb-6">
            <label className="mb-2 font-semibold text-gray-700">
              {t("mesure.date")}
            </label>
            <input
              type="date"
              value={form.date}
              onChange={(e) => setForm({ ...form, date: e.target.value })}
              className="bg-white border-2 border-emerald-500 text-gray-900 text-sm rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 w-full p-3"
              required
            />
          </div>
          <div className="flex flex-col mb-6">
            <label className="mb-2 font-semibold text-gray-700">
              {t("consultation.table_header.scikness")}
            </label>
            <select
              value={form.pathology}
              onChange={(e) => setForm({ ...form, pathology: e.target.value })}
              className="bg-white border-2 border-emerald-500 text-gray-900 text-sm rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 w-full p-3"
              required
            >
              <option value="" disabled>
                Sélectionnez une maladie
              </option>
              {pathologiesList.map((pathology) => (
                <option key={pathology.id} value={pathology.name}>
                  {pathology.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col mb-6">
            <label className="mb-2 font-semibold text-gray-700">
              {t("consultation.table_header.comment")}
            </label>
            <input
              type="text"
              value={form.comment}
              onChange={(e) => setForm({ ...form, comment: e.target.value })}
              className="bg-white border-2 border-emerald-500 text-gray-900 text-sm rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 w-full p-3"
            />
          </div>
          <div className="flex flex-col mb-6"></div>
          <button
            disabled
            type="submit"
            className="py-2 px-6 bg-green-600 text-white rounded-lg font-semibold shadow-md hover:bg-green-700 flex items-center focus:outline-none focus:ring-2 focus:ring-green-300 mb-6 ml-auto"
          >
            <span className="mr-2">Sauvegarder</span>
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </button>
        </form>
        <FindPharmacy />
        <Prescription
          onSave={() => console.log("Prescription sauvegardée :")}
        />

        <div className="history-section mt-8">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">
            {t("titles.history")}
          </h2>
          <InfoAlert message={t("titles.pop_no_recent_consultation")} />
          {childPathologiesList.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {childPathologiesList.map((c, index) => (
                <CardMonitoring
                  key={index}
                  time={c.date}
                  icone="consultation"
                  title={
                    pathologiesList.find((p) => p.id === c.pathologyId)?.name ||
                    ""
                  }
                  description={c.notes}
                  isWarning={false}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center text-center text-gray-500 mt-8">
              <p className="text-lg md:text-xl">
                {t("titles.no_recent_consultation")}
              </p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
