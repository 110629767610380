import axios from "axios";

const { REACT_APP_API_URL } = process.env;

const instance = axios.create({
  baseURL: REACT_APP_API_URL || "http://wing-fsn1-dc14.devolab.net:3008",
});

// Redirect to login page if token is expired
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      window.location.href = "/login";
      localStorage.removeItem("token");
      // maybe local popup to inform user that token is expired
    }
    return Promise.reject(error);
  }
);

export default instance;
