/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from "react";
import { ChildCard } from "../component/ChildCard";
import { WaveTitle } from "../component/WaveTitle";
import Pharmacy from "../assets/icons/64-removebg-preview.png";
import Phone from "../assets/icons/63-removebg-preview.png";
import Learn from "../assets/icons/he.png";
import { useTranslation } from "react-i18next";
import { ButtonIcon } from "../component/Button";
import axios from "../lib/config/axios";
import { useAuth } from "../hooks/useAuth";
import { useSnackbar } from "notistack";

interface Child {
  id: string;
  image_id: string;
  firstname: string;
}

export const Children = () => {
  const { t } = useTranslation();
  const [children, setChildren] = useState<Child[]>([]);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchChildren = async () => {
      try {
        const response = await axios.get("/children/all", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        setChildren(response.data);
        response.data.forEach((child: Child) => {
          localStorage.setItem(child.id.toString(), JSON.stringify(child));
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchChildren();
  }, [user]);

  const handleLogout = () => {
    axios
      .post(
        "/users/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          logout();
          window.location.href = "/login";
        } else {
          throw new Error("Failed to logout");
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to logout", {
          autoHideDuration: 2000,
          variant: "error",
        });
      });
  };

  return (
    <div className="min-h-screen bg-blue-50">
      <section className="flex flex-col gap-y-8 mx-4 md:mx-12 py-8">
        <div className="flex justify-between items-center mb-6">
          <WaveTitle
            color="blue"
            title={t("titles.children")}
            titleColor="black"
          />
          <button
            onClick={handleLogout}
            className="bg-blue-300 hover:bg-blue-400 text-white font-semibold text-lg py-2 px-6 rounded-full shadow-md transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-blue-200 flex items-center"
          >
            {t("children.logout")}
            <svg
              viewBox="0 0 512 512"
              className="w-5 h-5 fill-current text-white ml-2 transition-transform duration-300 hover:translate-x-1"
            >
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
            </svg>
          </button>
        </div>

        <div className="flex flex-col md:flex-row md:gap-16 justify-center items-center md:my-8 overflow-x-auto">
          {children.map((child) => (
            <ChildCard
              key={child.id}
              picture={child.image_id}
              name={child.firstname}
              urlRedirection={`/about-child/${child.id}`}
              hoverColor="blue-300"
              height="md:h-60 h-28 w-auto"
              fontSize="xl"
            />
          ))}
          <ChildCard
            picture="AddChild"
            name={t("children.add_child")}
            urlRedirection="/new-child"
            hoverColor="pink-500"
            height="h-20 md:h-32 w-auto"
            fontSize="xl"
            imageContainerClassName="h-28 w-28 md:w-60 md:h-60 flex items-center justify-center"
          />
        </div>
        <div className="hidden md:flex flex-row gap-20 items-center justify-center my-6">
          <a
            href="/pharmacy_box"
            className="flex flex-col justify-center items-center transition-all duration-500 hover:text-blue-500"
          >
            <img
              src={Pharmacy}
              alt="Pharmacy Box"
              className="md:h-24 h-16 rounded-full shadow-md hover:shadow-lg transition-transform duration-300 ease-out hover:scale-110"
            />
            <span className="font-semibold md:text-lg text-sm mt-2 text-gray-700">
              {t("children.pharmacy_box")}
            </span>
          </a>
          <a
            href="/children"
            className="flex flex-col justify-center items-center transition-all duration-500 hover:text-blue-500"
          >
            <img
              src={Phone}
              alt="Useful Numbers"
              className="md:h-24 h-16 rounded-full shadow-md hover:shadow-lg transition-transform duration-300 ease-out hover:scale-110"
            />
            <span className="font-semibold md:text-lg text-sm mt-2 text-gray-700">
              {t("children.useful_numbers")}
            </span>
          </a>
          <a
            href="/children"
            className="flex flex-col justify-center items-center transition-all duration-500 hover:text-blue-500"
          >
            <img
              src={Learn}
              alt="Learning"
              className="md:h-24 h-16 rounded-full shadow-md hover:shadow-lg transition-transform duration-300 ease-out hover:scale-110"
            />
            <span className="font-semibold md:text-lg text-sm mt-2 text-gray-700">
              {t("children.learning")}
            </span>
          </a>
        </div>

        <div className="md:hidden flex flex-col items-center space-y-4 my-4">
          <ButtonIcon
            text={t("children.pharmacy_box")}
            color="blue-300"
            urlRedirection="/pharmacy_box"
            icon={Pharmacy}
            width="80"
            disabled={false}
          />
          <ButtonIcon
            text={t("children.useful_numbers")}
            color="blue-300"
            urlRedirection="/children"
            icon={Phone}
            width="80"
            disabled={false}
          />
          <ButtonIcon
            text={t("children.learning")}
            color="blue-300"
            urlRedirection="/children"
            icon={Learn}
            width="80"
            disabled={false}
          />
        </div>
      </section>
    </div>
  );
};
