/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import "./Table.scss";
import AddSVG from "../../assets/icons/Add";
import { t } from "i18next";
import { FaEdit, FaTimes } from "react-icons/fa";

const TableRow = ({ columns, row, onSelect, onDelete }: any) => (
  <tr>
    {columns.map((col: any, index: number) => (
      <td key={index}>{row[col.accessor]}</td>
    ))}
    <td className="action-buttons">
      <button onClick={onSelect} className="button-edit">
        <FaEdit size={18} />
        <span>Modifier</span>
      </button>
      <button onClick={onDelete} className="button-delete">
        <FaTimes size={18} />
        <span>Supprimer</span>
      </button>
    </td>
  </tr>
);

const TableTreatment = ({
  title,
  data,
  columns,
  allergyList,
  manifestationList,
  medicineList,
  add,
  remove,
  onSelected,
}: any) => {
  const [viewNewItem, setViewNewItem] = useState(false);
  const [newItem, setNewItem] = useState({});

  const onConfirm = () => {
    add(newItem as never);
    setNewItem({});
    setViewNewItem(false);
  };

  const onChangeData = (type: string, value: string) => {
    setNewItem({ ...newItem, [type]: value });
  };

  return (
    <div className="table-container">
      <h2>{title}</h2>
      <div className="styled-background-table">
        <table className="styled-table">
          <thead>
            <tr>
              {columns.map((col: any, index: number) => (
                <th key={index}>{col.header.toUpperCase()}</th>
              ))}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row: any, rowIndex: number) => (
              <TableRow
                key={rowIndex}
                columns={columns}
                row={row}
                onDelete={() =>
                  remove(data.filter((_: any, i: number) => i !== rowIndex))
                }
                onSelect={() => onSelected && onSelected(row)}
              />
            ))}
          </tbody>
        </table>
      </div>
      {viewNewItem ? (
        <div className="add-container">
          {columns.map((col: any, idx: number) => (
            <div className="add-item" key={idx}>
              <p>{col.header}</p>
              {col.component === "input" ? (
                <input
                  type={col.type}
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                  placeholder={col.placeholder}
                />
              ) : (
                <select
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                >
                  <option value="chooseOption" disabled selected>
                    {t("new_child.form-data.placeholder.chooseOption")}
                  </option>
                  {(col.type === "allergyList"
                    ? allergyList
                    : col.type === "manifestationList"
                      ? manifestationList
                      : medicineList
                  )?.map((a: any) => (
                    <option key={a.id ?? a} value={a?.name ?? a}>
                      {a?.name ?? a}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
          <button
            className="add-button"
            type="button"
            onClick={onConfirm}
            disabled={
              !columns.every((col: any) => (newItem as any)?.[col.accessor])
            }
          >
            <span>AJOUTER</span>
          </button>
        </div>
      ) : (
        <button
          className="add-button"
          type="button"
          onClick={() => setViewNewItem(true)}
        >
          <AddSVG />
          <span>AJOUTER</span>
        </button>
      )}
    </div>
  );
};

export default TableTreatment;
