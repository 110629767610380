import React, { useState } from "react";
import { FaPills } from "react-icons/fa";
import { BsFillDropletFill } from "react-icons/bs";

const DosageInput = ({
  dosage,
  setDosage,
}: {
  dosage: string;
  setDosage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [unit, setUnit] = useState("mg");

  return (
    <div className="mb-6">
      <label className="flex items-center font-semibold mb-2 text-green-600">
        <FaPills className="mr-2" /> Dosage
      </label>
      <div className="flex border-2 border-green-300 rounded-lg">
        <input
          type="number"
          value={dosage}
          onChange={(e) => setDosage(e.target.value)}
          className="w-full p-3 border-none rounded-l-lg focus:outline-none"
          placeholder="Ex: 500"
          step="0.1"
        />
        <button
          type="button"
          className="flex items-center justify-center p-2 bg-gray-100 border-l-2 border-green-300 rounded-r-lg focus:outline-none"
          onClick={() => setUnit(unit === "mg" ? "g" : "mg")}
        >
          <BsFillDropletFill className="text-green-600 mr-1" />
          <span className="text-green-600 font-semibold">{unit}</span>
        </button>
      </div>
    </div>
  );
};

export default DosageInput;
