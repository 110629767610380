import React, { useState } from "react";
import Logo from "../assets/logo/logo-white.png";
import Profil from "../assets/icons/profil-white.png";
import Home from "../assets/icons/home-white.png";
import { useTranslation } from "react-i18next";
import FrenchFlag from "../assets/flags/fr.svg";
import EnglishFlag from "../assets/flags/en.svg";

export const NavBar = () => {
  const { t, i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("locale") || "fr"
  );

  const switchLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "fr" : "en";
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    localStorage.setItem("locale", newLanguage);
  };

  if (
    window.location.pathname === "/login" ||
    window.location.pathname === "/register"
  ) {
    return null;
  }

  return (
    <div className="md:h-16 h-12 px-4 w-full bg-blue-300 shadow-lg flex flex-row justify-between items-center rounded-b-2xl">
      <div className="ml-3 flex items-center">
        <a href="/">
          <img src={Logo} alt="BASSIST' Logo" className="md:w-32 w-24" />
        </a>
      </div>

      <div className="flex flex-row items-center gap-8 mr-4">
        <a
          href="/children"
          className="text-white flex flex-row items-center gap-2 hover:text-blue-500 transition duration-300 transform hover:scale-105"
        >
          <img src={Home} alt="Home" className="md:w-8 w-6" />
          <span className="hidden lg:block font-semibold">
            {t("navbar.menu")}
          </span>
        </a>

        <a
          href="/parent-profil"
          className="text-white flex flex-row items-center gap-2 hover:text-blue-500 transition duration-300 transform hover:scale-105"
        >
          <img src={Profil} alt="Profil" className="md:w-8 w-6" />
          <span className="hidden lg:block font-semibold">
            {t("navbar.profile")}
          </span>
        </a>

        <button
          onClick={switchLanguage}
          className="flex items-center justify-center md:w-10 w-8 h-8 bg-white rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-110"
        >
          <img
            src={currentLanguage === "fr" ? FrenchFlag : EnglishFlag}
            alt={currentLanguage === "fr" ? "French Flag" : "English Flag"}
            className="w-5"
          />
        </button>
      </div>
    </div>
  );
};
