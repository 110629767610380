import React from "react";

import WaveGreen from "../assets/illustrations/waves/wave-green.png";
import WavePink from "../assets/illustrations/waves/wave-pink.png";
import WaveBlue from "../assets/illustrations/waves/wave-blue.png";

interface WaveTitleProps {
  color: "green" | "pink" | "blue";
  title: string;
  titleColor: string;
}

export const WaveTitle: React.FC<WaveTitleProps> = ({
  color,
  title,
  titleColor,
}) => {
  return (
    <div className="flex flex-col">
      <img
        src={
          color === "green" ? WaveGreen : color === "pink" ? WavePink : WaveBlue
        }
        alt={`${color} background wave`}
        className="md:w-28 w-16 h-auto"
      />
      <h1 className={`mt-3 md:text-4xl text-lg font-bold text-${titleColor}`}>
        {title}
      </h1>
    </div>
  );
};
