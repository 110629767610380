import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const profilPic = [
  require("../../../assets/profilPic/0.png"),
  require("../../../assets/profilPic/1.png"),
  require("../../../assets/profilPic/2.png"),
  require("../../../assets/profilPic/3.png"),
  require("../../../assets/profilPic/4.png"),
  require("../../../assets/profilPic/5.png"),
  require("../../../assets/profilPic/6.png"),
  require("../../../assets/profilPic/7.png"),
  require("../../../assets/profilPic/8.png"),
  require("../../../assets/profilPic/9.png"),
  require("../../../assets/profilPic/10.png"),
  require("../../../assets/profilPic/11.png"),
  require("../../../assets/profilPic/12.png"),
];

interface SliderArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const SampleNextArrow: React.FC<SliderArrowProps> = ({
  className,
  style,
  onClick,
}) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#d1d5db",
        borderRadius: "100px",
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow: React.FC<SliderArrowProps> = ({
  className,
  style,
  onClick,
}) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#d1d5db",
        borderRadius: "100px",
      }}
      onClick={onClick}
    />
  );
};

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 4,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Carousel = ({
  active,
  onChange,
}: {
  active?: number;
  onChange: (idx: number) => void;
}) => {
  return (
    <div className="">
      <Slider {...settings}>
        {profilPic.map((item, index) => (
          <div
            className="rounded-full"
            key={index}
            onClick={() => onChange(index)}
          >
            <img
              src={item}
              alt="profilPic"
              className="h-40 W-40"
              style={
                active == index
                  ? { border: "3px solid black", borderRadius: "5rem" }
                  : {}
              }
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
