/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { ButtonIcon } from "../../component/Button";
import Bottle from "../../assets/icons/51-removebg-preview.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CardMonitoring } from "../../component/Cards";
// import { Calendar } from "../../component/Calendar";
import axios from "../../lib/config/axios";
import { useAuth } from "../../hooks/useAuth";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { availablesMonitorings } from "./constants";
import ArrowLeft from "../../assets/icons/Arrow/ArrowLeft";
import ArrowRight from "../../assets/icons/Arrow/ArrowRight";
import Header from "../../component/Header";

interface FoodItem {
  dateTime: string;
  quantity: number;
  id: string;
  // add other properties if needed
}

const FeedingModal = ({
  setOpen,
  setTemp,
  setTempData,
  monitoring,
  id,
  user,
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<any>({});

  const matchingForm = availablesMonitorings[2].form;

  const postUrl = availablesMonitorings
    .find((m) => m.name === monitoring)
    ?.postUrl(id);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!postUrl) return;
    const response = await axios.post(
      postUrl,
      { ...form, dateTime: new Date().toISOString() },
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );

    if (response.status === 201) {
      setOpen(false);
      setTemp((prevState: any) => [...prevState, response.data]);
      setTempData((prevState: any) => [...prevState, response.data]);
      enqueueSnackbar("Une notification appropriée apparaîtra ici", {
        autoHideDuration: 2000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      console.log("error");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white w-2/3 md:w-1/2 xl:w-1/3 p-8 rounded-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">
            Nouvelle donnée : {monitoring}
          </h2>
          <button onClick={() => setOpen(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form className="flex flex-col mt-4 gap-4" onSubmit={handleSubmit}>
          {matchingForm?.fields.map((field: any) => (
            <div key={field.name} className="flex flex-col gap-2">
              <label htmlFor={field.name}>{field.name}</label>
              {field.type === "select" ? (
                <select
                  value={form[field.field_name]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [field.field_name]: e.target.value,
                    })
                  }
                  id={field.name}
                  name={field.name}
                  className="border border-gray-300 rounded-md p-2"
                >
                  {field.options.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  value={form[field.field_name]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [field.field_name]:
                        field.type === "number"
                          ? Number(e.target.value)
                          : e.target.value,
                    })
                  }
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  className="border border-gray-300 rounded-md p-2"
                />
              )}
            </div>
          ))}
          <button
            type="submit"
            className="bg-primary text-white rounded-md p-2"
          >
            Ajouter
          </button>
        </form>
      </div>
    </div>
  );
};

export const Feeding = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const [temp, setTemp] = useState<FoodItem[]>([]);
  const [tempData, setTempData] = useState<FoodItem[]>([]);
  const { user } = useAuth();
  const [monitoringDate, setMonitoringDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchFood = async () => {
      try {
        const response = await axios.get(
          `/children/${child.id}/feeding-monitoring`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        setTemp(response.data);
        setTempData(
          response.data.filter(
            (t: any) =>
              new Date(t.dateTime).toDateString() ===
              monitoringDate.toDateString()
          )
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchFood();
  }, [child.id, user]);

  const dateFormated = (dateTime: string | number | Date) => {
    const dateObj = new Date(dateTime);
    // extract the hours and minutes
    return dateObj.toLocaleString("fr-FR", {
      hour: "numeric",
      minute: "numeric",
    });
  };

  const handleNextDate = () => {
    setMonitoringDate(
      new Date(monitoringDate.setDate(monitoringDate.getDate() + 1))
    );
    setTempData(
      temp.filter(
        (t: any) =>
          new Date(t.dateTime).toDateString() === monitoringDate.toDateString()
      )
    );
  };

  const handlePreviousDate = () => {
    setMonitoringDate(
      new Date(monitoringDate.setDate(monitoringDate.getDate() - 1))
    );
    setTempData(
      temp.filter(
        (t: any) =>
          new Date(t.dateTime).toDateString() === monitoringDate.toDateString()
      )
    );
  };

  const handleDeleteCard = async (id: string | undefined) => {
    if (!id) return;
    try {
      await axios.delete(`/children/${child.id}/feeding-monitoring/${id}`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
      setTempData((prevCards) => [
        ...prevCards.filter((card) => card.id !== id),
      ]);
      enqueueSnackbar("La donnée a été supprimée", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.error("Failed to delete the card:", error);
      enqueueSnackbar("Échec de la suppression de la donnée", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };
  return (
    <div className="w-screen">
      {open && (
        <FeedingModal
          setOpen={setOpen}
          setTemp={setTemp}
          setTempData={setTempData}
          monitoring="Alimentation"
          id={id}
          user={user}
        />
      )}
      <section className="flex flex-col justify-between  w-full">
        <Header
          title={t("titles.monitoring")} // Tu peux choisir le Titre que tu veux
          titleColor="green" // Tu peux choisir la couleur que tu veux
          childInfo={{
            picture: child.image_id,
            name: child.firstname,
            urlRedirection: `/child-profile/${child.id}`,
          }}
          showBackToMenu={true}
        />
        <div className="flex flex-row items-center px-12 justify-between">
          <ButtonIcon
            text={t("titles.feeding")}
            color="green-500"
            urlRedirection={`/monitoring/${id}`}
            icon={Bottle}
            width="1/3"
            disabled={false}
          />
          <button
            type="button"
            className="mt-4 flex items-center py-1 px-3 text-sm bg-green-500 text-white rounded-full cursor-pointer font-semibold text-center shadow-xs transition-all duration-500 hover:bg-green-600"
            onClick={() => setOpen(true)}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1 h-6"
            >
              <path
                d="M12 6V18"
                stroke="white"
                strokeWidth="1.6"
                strokeLinecap="round"
                className="my-path"
              ></path>
              <path
                d="M18 12H6"
                stroke="white"
                strokeWidth="1.6"
                strokeLinecap="round"
                className="my-path"
              ></path>
            </svg>
            {t("buttons.new_entry")}
          </button>
        </div>
        <div className="flex justify-center items-start gap-x-8 my-4">
          <button
            className="rounded-xl hover:bg-gray-100 p-2"
            onClick={() => handlePreviousDate()}
          >
            <ArrowLeft />
          </button>
          <h2 className="mb-4 font-semibolf text-primary text-2xl">
            {new Date(monitoringDate).toLocaleDateString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </h2>
          <button
            className="rounded-xl hover:bg-gray-100 p-2 disabled:opacity-10"
            onClick={() => handleNextDate()}
          >
            <ArrowRight />
          </button>
        </div>

        <div className="grid md:grid-cols-4 grid-cols-2 mt-20 gap-8">
          {tempData.map((f: FoodItem, index) => (
            <div
              className="flex flex-row items-center justify-center"
              key={index}
            >
              <CardMonitoring
                time={dateFormated(f.dateTime)}
                icone="51-removebg-preview"
                isWarning={true}
                title={f.quantity + " ml"}
                description=""
                onDelete={() => handleDeleteCard(f.id)}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
